import Head from "next/head";
import Script from "next/script";

export default function Heads(props) {
    
    const title = props.title + "- Amperius"

    return (
    <>
    <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="Pour véhicules électriques et hybrides rechargeables. Des installations de bornes de recharge de qualité effectuées par une équipe de professionnels qualifiés." />
        
        <meta property="og:title" content={`${title} - Amperius`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.amperius.be" />
        <meta property="og:site_name" content="Amperius" />
        <meta property="og:description" content="Pour véhicules électriques et hybrides rechargeables. Des installations de bornes de recharge de qualité effectuées par une équipe de professionnels qualifiés." />

        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />

    </Head>
    <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-5KBDQTMRCL"
        strategy="afterInteractive"
      />
    <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', 'G-5KBDQTMRCL');
        `}
    </Script>
    </>
    )
}
