import Image from 'next/image'
import { Col, Container, Row, Button } from 'react-bootstrap'
import styles from '../styles/why.module.css'
import { useRouter } from 'next/router'


export default function Why() {

    const router = useRouter()

    return (
            <div className={styles.top} id='why'>
                <svg className={styles.shape} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path d="M0,6V0h1000v100L0,6z" fill="white"></path>
                </svg>

                <Container className="px-5 py-5 just">
                    <Row className='justify-content-center'>
                        {/* <Col md={5} className='d-flex justify-content-center align-items-center pb-0 pb-md-0'>
                                <Image src='/images/why.png' width={297} height={185} alt="Pourquoi"/>
                        </Col> */}
                        <Col lg={7} className='d-flex flex-column justify-content-center'>
                            <h2 className='pb-4'>Pourquoi <span className='text-primary'>Amperius</span> ?</h2>
                            <p className='text-dark'>Une projet, un besoin, une solution ! Amperius vous accompagne à chaque étape. </p>
                            <p className='text-dark'>Des installations certifiées et effectuées par une équipe de professionnels qualifiés.</p>
                            <p className='text-dark text-center fs-5'><b><span className='text-primary'>Votre satisfaction, notre priorité absolue.</span></b></p>
                            
                            {/* <p>Chaque projet est unique ! Charge.One vous accompagne tout au long de votre projet. Nous évaluons votre besoin, nous proposons la solution le plus adaptée et nous installons votre borne de recharge. </p>
                            <p>Toutes nos installations sont certifiées et effectuées par une équipe de professionnels qualifiés et soucieux de fournir un travail de qualité.</p>
                            <p>Chez Plugl la satisfaction client est notre priorité. Votre projet ne se limite pas à l’installation, nous assurons également le suivi et l’entretien de vos bornes de recharges. Plugl c’est tout simplement la solution sans tracas qui vous simplifie la vie.</p>
                            <p>L'option number .One</p> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-block text-center my-3 mt-4">
                                <Button className='btn-rounded' onClick={() => router.push('/devis')}><span className='fs-5'>Devis gratuit en quelques clics</span></Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
        
                <svg className={`${styles.shape} ${styles.shape_bottom}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path d="M0,6V0h1000v100L0,6z" fill="white"></path>
                </svg>
            </div>
            
    )
}