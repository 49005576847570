import Image from 'next/image'
import { useRouter } from 'next/router'
import { Col, Container, Row, Button} from 'react-bootstrap'
import styles from '../styles/advantages.module.css'

export default function Advantages() {

    const router = useRouter()

    return (
        <div className={styles.top}>
            <svg className={`${styles.shape} ${styles.shape_top}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path d="M0,6V0h1000v100L0,6z" fill="white"></path>
            </svg>

            <Container className="px-5 py-4">
                <Row>
                    <Col md={5} className='d-flex justify-content-center align-items-center'>
                            <Image src="/images/advantages_car.png" width={350} height={192} alt="Advantages"/>
                    </Col>
                    <Col md={7} className='d-flex flex-column justify-content-center'>
                        <h2 className='pb-4'>Les avantages d'une borne de recharge</h2>
                        <ol className={styles.list}>
                            <li>Grâce à une plus grande puissance disponible, rechargez votre véhicule électrique dix à douze fois plus rapidement avec une borne de recharge.</li>
                            <li>Évitez la surchauffe et les risques d'incendie. Les bornes de recharges sont spécialement conçues pour délivrer toute la puissance nécessaire en toute sécurité.</li>
                            <li>Les bornes connectées permettent de contrôler à distance la recharge de votre véhicule.</li>
                        </ol>
                        <p >Partez l’esprit tranquille avec les batteries pleines !</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-block text-center my-3">
                            <Button className='btn-rounded' onClick={() => router.push('/devis')}><span className='fs-5'>Devis gratuit en quelques clics</span></Button>
                        </div>
                    </Col>
                </Row>
            </Container>
    
            <svg className={`${styles.shape} ${styles.shape_bottom}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path d="M0,6V0h1000v100L0,6z" fill="white"></path>
            </svg>
        </div>
        
    )
}
