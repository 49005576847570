import { useRouter } from 'next/router'
import { Container, Row, Col, Button } from 'react-bootstrap'
import styles from '../styles/header.module.scss'


export default function Header() {

    const router = useRouter()

    return (
        <header className={styles.header}>
            <div className={`h-100 position-absolute d-md-block d-none ${styles.obliqueDiv}`}>
                <div className={`h-100 ${styles.backgroundImage}`}></div>
            </div>
            <Container className={styles.headerText}>
                <Row className={styles.headerTextRow}>
                    <Col lg={6} md={7} className={`d-flex justify-content-center flex-column px-3`}>
                        <h1 className={`my-3 text-center ${styles.title}`}><span className='fw-lighter'>La recharge facile <br /> avec </span><span className={`fw-light ${styles.brand}`}>Amperius</span></h1>
                        <p className='my-3 fw-light fs-5 text-center'>Un accompagnement sur mesure, des démarches simplifiées et un service client de qualité.</p>
                        <div className="d-block text-center my-3">
                            <Button className='btn-rounded' onClick={() => router.push('/devis')}><span className='fs-5'>Devis gratuit en quelques clics</span></Button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className={styles.iconScroll}></div>

            <div className={styles.svgRoundedBorder}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor">
                            <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
            </svg>
            </div>
        </header>
    )
}