import Image from 'next/image';
import Link from 'next/link';
import styles from '../styles/navbar.module.css'

import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useRouter } from 'next/router';
// import { useEffect } from 'react';

export default function NavBar() {

    const router = useRouter()

    // // Track the hashs in URL and avoid error when element id does not exist
    // useEffect(() => {
    //     const onHashChangeStart = (url) => {
    //         console.log(`Path changing to ${url}`);
    //         if (url.substring(1).length > 1 ) {
    //             try {
    //                 document.getElementById('a_'+decodeURI(url.substring(1)).replace(/^#/, '')).scrollIntoView({behavior: 'smooth', block: 'center'})
    //             } catch {}
    //         }
    //     };

    //     router.events.on("hashChangeStart", onHashChangeStart);

    //     return () => {
    //         router.events.off("hashChangeStart", onHashChangeStart);
    //     };
    // }, [router.events]);

    const paddingNavLink = 'px-4'

    return (
        <Navbar collapseOnSelect expand="lg" className={`p-2 ${styles.navbar}`} sticky="top">
        <Container>
            <Navbar.Brand href="/">
                <Image src="/images/icon.png" alt='Logo amperius' width={227} height={43} priority={true}  />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto ms-auto" activeKey="">
                    <Nav.Link className={paddingNavLink} href="/" onClick={() => router.push('/')}> Accueil</Nav.Link>
                    <Nav.Link className={paddingNavLink} href="/#solutions" onClick={() => router.push('/#solutions') } >Nos solutions</Nav.Link>
                    <Nav.Link className={paddingNavLink} href="/#why" onClick={() => router.push('/#why')} >Pourquoi Amperius</Nav.Link>
                    <Nav.Link className={paddingNavLink} href="/#contact" onClick={() => router.push('/#contact')} >Nous contacter</Nav.Link>
                </Nav>
                <Nav>   
                    <Button variant='outline-primary' onClick={() => router.push('/devis')} className='btn-rounded'>Devis gratuit</Button>
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}