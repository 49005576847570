import Image from 'next/image'
import Link from 'next/link'
import { Container, Row, Col } from 'react-bootstrap'
import {BsFacebook, BsGoogle, BsLinkedin, BsEnvelopeFill, BsFillTelephoneFill} from 'react-icons/bs'
import {FaBuilding} from 'react-icons/fa'

export default function Footer() {

    

    const showContact = () => {
        const encodedEmail = 'aW5mb0BhbXBlcml1cy5iZQ=='
        const emailDOM = document.getElementById('hiddenContact')
        emailDOM !== 'undefinned' ? setTimeout(() => emailDOM.innerHTML = Buffer.from(encodedEmail, 'base64').toString('utf-8'), 250)  : ''
    }

    return (<>
    <footer className='mt-5' id='contact'>
        <section className="d-flex justify-content-between p-4 border-bottom" >
            <div>
                Rejoignez-nous sur les réseaux sociaux :
            </div>
            <div>
                <Link href="https://www.facebook.com/profile.php?id=100087423474148" className="me-4 text-reset"><BsFacebook /></Link>
                {/* <Link href="#" className="me-4 text-reset"><BsGoogle /></Link> */}
                <Link href="https://www.linkedin.com/company/amperius/" className="me-4 text-reset"><BsLinkedin /></Link>
                
            </div>
        </section>

        <section>
            <Container className=' mt-5 mb-4'>
                <Row>
                    <Col sm='8'>
                    <Image src="/images/icon.png" alt='Logo Amperius' width={227} height={43} priority={true}  />
                        <p className='mt-3'>La recharge facile</p>
                    </Col>
                    <Col sm='4'>
                        <p className='fs-4 fw-bold'>Contact</p>
                        <p><FaBuilding className='me-3'/> Rue du Louvroy 18 , 6120 Nalinnes</p>
                        <p><BsEnvelopeFill className='me-3' /><span id='hiddenContact' 
                                                                    onClick={() => showContact()}
                                                                    style={{cursor: "pointer"}}
                                                            >
                                                                        Email ? Cliquez ici ! 
                                                            </span></p>
                        <p><BsFillTelephoneFill className='me-3' /> +32 (0)499.61.39.49</p>
                    </Col>
                </Row>
            </Container>
        </section>
    </footer>
    </>)
}