import { Container } from 'react-bootstrap'
import styles from '../styles/procedure.module.css'
import Image from 'next/image'
import imagePlug from '../public/images/plug-charge.jpg'

export default function Procedure() {
    return (
        <Container className='text-center mt-5'>
            <h2 className='fw-light fs-1 mb-5'><span className={`${styles.brandColor}`}>La simplicité en <b className='text-primary'>4 étapes</b></span></h2>
            <div className={`${styles.main_timeline} ms-4 me-2`}>
                <div className={styles.timeline}>
                    <div className={styles.timeline_icon}></div>
                    <div className={`${styles.timeline_content} ${styles.right}`}>
                        <span className={`${styles.date} ${styles.desktop_mobile_icon}`}></span>
                        <h3 className={styles.title}>1 - Devis gratuit en ligne</h3>
                        <p className={styles.description}>
                        En quelques clics demandez une offre gratuite en ligne
                        </p>
                    </div>
                </div>
                <div className={styles.timeline}>
                    <div className={styles.timeline_icon}></div>
                    <div className={`${styles.timeline_content}`}>
                        <span className={`${styles.date} ${styles.diagnostic}`}></span>
                        <h3 className={styles.title}>2 - Expertise</h3>
                        <p className={styles.description}>
                        Un de nos experts vous contacte et convient d'un rendez-vous pour faire l'état des lieux de votre installation électrique et vérifier vos besoins
                        </p>
                    </div>
                </div>
                <div className={styles.timeline}>
                    <div className={styles.timeline_icon}></div>
                    <div className={`${styles.timeline_content} ${styles.right}`}>
                        <span className={`${styles.date} ${styles.quote}`}></span>
                        <h3 className={styles.title}>3 - Offre</h3>
                        <p className={styles.description}>
                        Sur base de l'expertise, nous vous envoyons une offre pour l'installation de votre ou vos bornes de recharges.
                        </p>
                    </div>
                </div>
                <div className={styles.timeline}>
                    <div className={styles.timeline_icon}></div>
                    <div className={`${styles.timeline_content}`}>
                        <span className={`${styles.date} ${styles.installation}`}></span>
                        <h3 className={styles.title}>4 - Installation</h3>
                        <p className={styles.description}>
                        Un de nos experts vient installer votre borne à la date convenue. Nous faisons ensuite certifier l'installation par un organisme de contrôle agréé.                   
                        </p>
                    </div>
                </div>
            </div>
        <div className={styles.endImage}>
            <Image src={imagePlug} alt='Véhicule électrique avec sa prise de recharge' sizes="100vw" style={ {width: '100%', height: 'auto'} } />
        </div>
        </Container>
    )
}