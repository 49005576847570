import { Col, Container, Row } from 'react-bootstrap'
import styles from '../styles/offer.module.css'


export default function Offer() {
    return (
            <div id='solutions'>
                <Container className={`px-5 mt-5 text-center ${styles.container}`}>
                    <h2 className='fw-light fs-1'><span className={`${styles.brandColor}`}>Amperius</span> a la solution !</h2>
                    <Row className='g-5 pt-5 row-cols-1 row-cols-md-3'>
                        <Col className={styles.col}>
                            <div className={`pb-4 ${styles.icon}`}>
                                <div className={styles.solutions}></div>
                            </div>
                            <p>À domicile ou en entreprise, nous avons une <b>solution adaptée</b> à vos besoins.</p>
                        </Col>
                        <Col className={styles.col}>
                            <div className={`pb-4 ${styles.icon}`}>
                                <div className={styles.evChargerIcon}></div>
                            </div>
                            <p>Nous proposons une large gamme de <b>bornes intelligentes</b> et <b>pilotables</b>.</p>
                        </Col>
                        <Col className={styles.col}>
                            <div className={`pb-4 ${styles.icon}`}>
                                <div className={styles.power}></div>
                            </div>
                            <p>La recharge rapide à portée de main avec une puissance adaptée à vos besoins de <b>3,7kW</b> à <b>22kW</b>.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            
    )
}
