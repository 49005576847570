import NavBar from '../components/navbar'
import Header from '../components/header'
import Why from '../components/why'
import Advantages from '../components/advantages'
import Footer from '../components/footer'
import Offer from '../components/offer'
import Procedure from '../components/procedure'
import Heads from '../components/heads'

export default function Home() {

  return (
    <>
      <Heads title="Installation bornes de recharge véhicules électriques" />

      <NavBar/>
      <Header/>
      
      <section>
        <Offer/>
      </section>
      <section>
        <Advantages />
      </section>
      
      <section>
        <Procedure />
      </section>
      
      <section>
        <Why />
      </section>
      {/* <Financing /> */}
      
      <Footer />
    </>
  )
}

